import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import { toServerTimeline } from "./timeline-api"
import { StudioPitch } from "../../models/studio-pitch-info"
import { Pitch, PitchActionType, PitchStatus, PitchType } from "../../models/pitch"
import { Timeline } from "../../models/timeline"
import { Attachment } from "../../models/attachment"

const API_PATH = "api/Pitch/"

export type PlaybackUrlResult = { playbackUrl: string; isArchived: boolean }

export type MuxTokensResult = {
  playbackId: string
  playbackToken: string
  thumbnailToken: string
  storyboardToken: string
  isArchived: boolean
}

export type PublishedPitchCountResult = { publishedPitchCount: number }

export type PitchResult = { pitch: Pitch }

export type NullablePitchResult = { pitch?: Pitch }

type PitchDraft = {
  id: string
  name: string
  description?: string
  type: PitchType
  timeline: Timeline
  status: PitchStatus
}

export type PitchDraftResult = {
  pitchDraft: PitchDraft
}

export type StudioPitchResult = { pitch: StudioPitch }

export type StudioPitchesResult = { pitches: StudioPitch[] }

export type PitchesResult = { pitches: Pitch[] }

export class PitchApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getPitch({ pitchId }): Promise<PitchResult> {
    const response: ApiResponse<Pitch> = await this.api.apisauce.get(API_PATH + pitchId)
    if (!response.ok || !response.data) {
      throw new ApiError("PitchApi.getPitch", response)
    }

    return { pitch: response.data }
  }

  async deletePitch({ pitchId }): Promise<void> {
    const response: ApiResponse<Pitch> = await this.api.apisauce.delete(API_PATH + pitchId)

    if (!response.ok) {
      throw new ApiError("PitchApi.deletePitch", response)
    }
  }

  async getDraft({ pitchId }): Promise<PitchDraftResult> {
    const response: ApiResponse<PitchDraft> = await this.api.apisauce.get(
      API_PATH + `${pitchId}/draft`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PitchApi.getDraft", response)
    }
    return { pitchDraft: response.data }
  }

  async getStudioPitch({ pitchId }): Promise<StudioPitchResult> {
    const response: ApiResponse<StudioPitch> = await this.api.apisauce.get(
      API_PATH + `${pitchId}/studio`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PitchApi.getStudioPitch", response)
    }
    return { pitch: response.data }
  }

  async getPlaybackUrl(pitchId): Promise<PlaybackUrlResult> {
    const response: ApiResponse<PlaybackUrlResult> = await this.api.apisauce.get(
      API_PATH + `${pitchId}/stream/url`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PitchApi.getPlaybackUrl", response)
    }
    return response.data
  }

  async getPlaybackTokens(pitchId): Promise<MuxTokensResult> {
    const response: ApiResponse<MuxTokensResult> = await this.api.apisauce.get(
      API_PATH + `${pitchId}/stream/tokens`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PitchApi.getPlaybackTokens", response)
    }
    return response.data
  }

  async getAllUserPitches(entityIds?: string[]): Promise<StudioPitchesResult> {
    const response: ApiResponse<StudioPitchesResult> = await this.api.apisauce.get(
      API_PATH + "all/user",
      {
        entityIds,
      },
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PitchApi.getAllUserPitches", response)
    }
    return response.data
  }

  async getProfilePitch(userId: string): Promise<NullablePitchResult> {
    const response: ApiResponse<NullablePitchResult> = await this.api.apisauce.get(
      API_PATH + `profile/user/${userId}`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PitchApi.getProfilePitch", response)
    }
    return response.data
  }

  async savePitchTimeline(pitchId: string, name: string, timeline: Timeline): Promise<void> {
    const response: ApiResponse<any> = await this.api.apisauce.put(
      API_PATH + `${pitchId}/timeline`,
      {
        name,
        timeline: toServerTimeline(timeline),
      },
    )

    if (!response.ok) {
      throw new ApiError("PitchApi.savePitchTimeline", response)
    }
  }

  async savePitchAttachments(
    pitchId: string,
    name: string,
    description: string,
    attachments: Attachment[],
  ): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.put(
      API_PATH + `${pitchId}/attachments`,
      {
        name,
        description,
        attachments: attachments.map((a) => {
          return Object.assign({}, a, { data: JSON.stringify(a.data) })
        }),
      },
    )
    if (!response.ok) {
      throw new ApiError("PitchApi.savePitchAttachments", response)
    }
  }

  async setEntity(pitchId: string, entityId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      API_PATH + `${pitchId}/entity/${entityId}`,
    )
    if (!response.ok) {
      throw new ApiError("PitchApi.setEntity", response)
    }
  }

  async copyAsDraft(
    originalPitchId: string,
    newPitchId: string,
  ): Promise<{ copiedPitchId: string }> {
    const response: ApiResponse<{ copiedPitchId: string }> = await this.api.apisauce.post(
      API_PATH + `${originalPitchId}/copy?newPitchId=${newPitchId}`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PitchApi.copyAsDraft", response)
    }
    return response.data
  }

  async archivePitch(pitchId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      API_PATH + `${pitchId}/archive`,
    )
    if (!response.ok) {
      throw new ApiError("PitchApi.archivePitch", response)
    }
  }

  async unarchivePitch(pitchId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      API_PATH + `${pitchId}/unarchive`,
    )
    if (!response.ok) {
      throw new ApiError("PitchApi.unarchivePitch", response)
    }
  }

  async setProfilePitch(userId, pitchId): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      API_PATH + `profile/${userId}`,
      { pitchId },
    )
    if (!response.ok) {
      throw new ApiError("PitchApi.setProfilePitch", response)
    }
  }

  async removePrimaryProfilePitch(userId): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.delete(
      API_PATH + `profile/${userId}`,
    )
    if (!response.ok) {
      throw new ApiError("PitchApi.removePrimaryProfilePitch", response)
    }
  }

  async createAction(
    pitchId,
    data: {
      actionType: PitchActionType
      actionEntityId: string
      actionTargetId?: string
      assignmentUserId?: string
      scheduledUtc?: Date
    },
  ): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.put(
      API_PATH + `${pitchId}/action`,
      data,
    )
    if (!response.ok) {
      throw new ApiError("PitchApi.createAction", response)
    }
  }

  async initiateAction(pitchId): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      API_PATH + `${pitchId}/action/initiate`,
    )
    if (!response.ok) {
      throw new ApiError("PitchApi.initiateAction", response)
    }
  }

  async cancelAction(pitchId): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      API_PATH + `${pitchId}/action/cancel`,
    )
    if (!response.ok) {
      throw new ApiError("PitchApi.cancelAction", response)
    }
  }

  async getPublishedPitchCount({ userId }): Promise<PublishedPitchCountResult> {
    const response: ApiResponse<PublishedPitchCountResult> = await this.api.apisauce.get(
      `${API_PATH}count/published/${userId}`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("PitchApi.getPublishedPitchCount", response)
    }

    return response.data
  }
}
