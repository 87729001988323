import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"
import { Group } from "../../models/group"
import { PublicUser } from "../../models/public-user"
import { ExternalLink } from "../../models/external-link"

type GroupResult = { group: Group }

type GroupsResult = { groups: Group[] }

type DashboardGroupSummaryResult = {
  groupSummaries: {
    groupId: string
    admins: PublicUser[]
    memberCount: number
  }[]
}

export type CheckCanDeleteGroupResult = {
  canDelete: boolean
}

const API_PATH = "api/Group/"

export class GroupApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getGroup(groupId: string): Promise<GroupResult> {
    const response: ApiResponse<Group> = await this.api.apisauce.get(`${API_PATH}${groupId}`)
    if (!response.ok || !response.data) {
      throw new ApiError("GroupApi.getGroup", response)
    }
    return { group: response.data }
  }

  async getByOrganizationId(organizationId: string): Promise<GroupsResult> {
    const response: ApiResponse<GroupsResult> = await this.api.apisauce.get(
      `${API_PATH}organization/${organizationId}`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("GroupApi.getByOrganizationId", response)
    }
    return response.data
  }

  async getGroupsForDashboard(organizationId: string): Promise<GroupsResult> {
    const response: ApiResponse<GroupsResult> = await this.api.apisauce.get(
      `${API_PATH}organization/${organizationId}/admin`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("GroupApi.getGroupsForDashboard", response)
    }
    return response.data
  }

  async getDashboardGroupSummaries(organizationId: string): Promise<DashboardGroupSummaryResult> {
    const response: ApiResponse<DashboardGroupSummaryResult> = await this.api.apisauce.get(
      `${API_PATH}summary/${organizationId}`,
    )
    if (!response.ok || !response.data) {
      throw new ApiError("GroupApi.getDashboardGroupSummaries", response)
    }
    return response.data
  }

  async createGroup(group: Group): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.post(API_PATH, group)
    if (!response.ok) {
      throw new ApiError("GroupApi.createGroup", response)
    }
  }

  async updateGroup(group: Group): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.patch(
      `${API_PATH}${group.id}`,
      group,
    )
    if (!response.ok) {
      throw new ApiError("GroupApi.updateGroup", response)
    }
  }

  async updateGroupSettings(group: Group): Promise<void> {
    const response: ApiResponse<any> = await this.api.apisauce.put(
      `${API_PATH}${group.id}/settings`,
      group,
    )
    if (!response.ok) {
      throw new ApiError("GroupApi.updateGroupSettings", response)
    }
  }

  async deleteGroup(groupId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.delete(`${API_PATH}${groupId}`)
    if (!response.ok) {
      throw new ApiError("GroupApi.deleteGroup", response)
    }
  }

  async getGroupProfileShareLink(groupId: string): Promise<ExternalLink | undefined> {
    const response: ApiResponse<ExternalLink | undefined> = await this.api.apisauce.post(
      `${API_PATH}${groupId}/share`,
    )
    if (!response.ok) {
      throw new ApiError("GroupApi.getGroupProfileShareLink", response)
    }
    return response.data
  }
}
