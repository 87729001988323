import React from "react"
import Image from "next/legacy/image"
import { CSSObject, useTheme } from "@emotion/react"
import { Text } from "../text/text"
import { AppTheme } from "pitch45-common/theme/app-theme.types"
import { textPresets } from "../text/text.presets"
import { color } from "pitch45-common/theme"

interface EntityAvatarProps {
  uri?: string
  name: string
  width: number
  borderRadius?: number
  borderWidth?: number
  borderColor?: string
  layout?: "fill" | "fixed" | "intrinsic" | "responsive"
  style?: CSSObject
}

function getStyles(theme: AppTheme, width: number) {
  const styles = {
    PLACEHOLDER_CONTAINER: {
      backgroundColor: color.palette.slateGrey,
      width: `${width}px`,
      height: `${width}px`,
      minWidth: `${width}px`,
      minHeight: `${width}px`,
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }
  return styles as { [key in keyof typeof styles]: CSSObject }
}

const getEntityNameInitials = (name: string) => {
  if (name.length === 0) {
    return "?"
  }
  return name.charAt(0).toUpperCase()
}

export const EntityAvatar = (props: EntityAvatarProps) => {
  const { uri, width, name, borderRadius, style } = props
  const theme = useTheme() as AppTheme
  const styles = getStyles(theme, width)

  if (!uri) {
    return (
      <div css={{ ...style, ...styles.PLACEHOLDER_CONTAINER }}>
        <Text
          preset={textPresets.largeSemibold}
          css={{
            fontSize: `calc(${width}px / 2)`,
            verticalAlign: "middle",
            marginTop: `calc(${width}px / 8)`, // Needed to get capitalized letters centered in circle
            color: color.palette.white,
          }}
        >
          {getEntityNameInitials(name)}
        </Text>
      </div>
    )
  }

  const imageStyles: {
    borderRadius: string | number
    border?: string
  } = {
    borderRadius: borderRadius ?? "50%",
  }

  if (props.borderWidth && props.borderColor) {
    imageStyles.border = `${props.borderWidth}px solid ${props.borderColor} !important}`
  }

  return (
    <div css={{ width, height: width, ...style }}>
      <Image
        src={uri}
        width={width}
        height={width}
        alt={name}
        css={imageStyles}
        layout={props.layout || "intrinsic"}
        unoptimized
      />
    </div>
  )
}

export default EntityAvatar
