import { ApiResponse } from "apisauce"
import { Api } from "./api"
import { ApiError } from "./api-problem"

const API_PATH = "api/GroupUser/"

export class GroupUserApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async updateGroupUserByManagedRole(groupUserId: string, roleId: string): Promise<void> {
    const response: ApiResponse<void> = await this.api.apisauce.patch(
      `${API_PATH}${groupUserId}/roles`,
      {
        organizationRoleId: roleId,
      },
    )
    if (!response.ok) {
      throw new ApiError("GroupUserApi.updateGroupUserByManagedRole", response)
    }
  }

  async assignGroupUsers(
    organizationId: string,
    groupId: string,
    organizationRoleId: string,
    userIds: string[],
  ) {
    const response: ApiResponse<void> = await this.api.apisauce.post(
      `${API_PATH}${organizationId}/assign`,
      {
        groupUsers: userIds.map((userId) => ({
          groupId,
          userId,
          organizationRoleId,
        })),
      },
    )
    if (!response.ok) {
      throw new ApiError("GroupUserApi.assignGroupUsers", response)
    }
  }
}
