import { cast, flow, Instance, toGenerator, types } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import newId from "../utils/new-id"
import { Pitch, PitchDetailsModel, PitchType } from "../models/pitch"
import { SyncStatus } from "../models/sync"
import { PitchApi } from "../services/api/pitch-api"
import { withTimelineStore } from "./timeline-store"
import { BaseAttachmentEditorStoreModel } from "./base-attachment-editor-store"
import { AttachmentApi } from "../services/api/attachment-api"
import { Timeline } from "../models/timeline"
import { withPitchStore } from "./pitch-store"
import { format } from "date-fns"
import { isEqual } from "lodash-es"
import { DATE_FORMAT } from "../utils"
import { PitchIntentProps, withPitchIntentStore } from "./pitch-intent-store"
import { AttachmentLabel, AttachmentType } from "../models/attachment"
import { StreamableSummaryStatus, StudioPitchInfo } from "../models/studio-pitch-info"
import logger from "../logging/logger"

export const PitchDraftModel = types.model("PitchDraft").props({
  id: types.optional(types.string, () => newId().id),
  defaultName: types.maybe(types.string),
  details: types.maybe(PitchDetailsModel),
  copiedFromId: types.maybe(types.string),
})

export interface PitchDraft extends Instance<typeof PitchDraftModel> {}

export const PitchEditorStoreModel = BaseAttachmentEditorStoreModel.named("PitchEditorStore")
  .props({
    draft: types.maybe(PitchDraftModel),
  })
  .extend(withTimelineStore)
  .extend(withPitchStore)
  .extend(withPitchIntentStore)
  .extend(withEnvironment)
  .actions((self) => ({
    reset() {
      self.draft = undefined
      self.resetAttachments()
    },
    setDetails(type: PitchType, name: string) {
      if (self.draft?.details) {
        self.draft.details.type = type
        self.draft.details.name = name
      }
    },
    setId(id: string) {
      if (self.draft) {
        self.draft.id = id
      }
    },
    setName(name: string) {
      if (self.draft?.details) {
        self.draft.details.name = name
      }
    },
    setType(type: PitchType) {
      if (self.draft?.details) {
        self.draft.details.type = type
      }
    },
    setDescription(description: string) {
      if (self.draft?.details) {
        self.draft.details.description = description
      }
    },
  }))
  .actions((self) => ({
    createCopy: flow(function* (pitch: Pitch) {
      self.draft = cast<PitchDraft>({
        defaultName: `Copy of ${pitch.name}`,
        details: {
          type: pitch.type,
          description: pitch.description || "",
        },
        copiedFromId: pitch.id,
      })

      try {
        const attachmentApi = new AttachmentApi(self.environment.api)
        const attachments = yield* toGenerator(
          attachmentApi.getAttachmentsForPitch({ pitchId: pitch.id }),
        )
        attachments.attachments.forEach((attachment) => {
          if (attachment.type) {
            self.setAttachment({
              type: attachment.type as AttachmentType,
              data: attachment.data as any,
              label: attachment.label as AttachmentLabel,
            })
          }
        })
      } catch (error) {
        logger.logError("Error getting attachments for copy from draft", {
          pitchId: pitch.id,
          error,
        })
      }

      return self.draft
    }),
    createExistingDraft(pitch: Pitch) {
      self.draft = cast<PitchDraft>({
        id: pitch.id,
        defaultName: pitch.name,
        details: {
          type: pitch.type,
          name: pitch.name,
          description: pitch.description,
        },
      })

      return self.draft
    },
    createNewDraft() {
      self.draft = cast<PitchDraft>({
        defaultName: `Untitled ${format(new Date(), DATE_FORMAT)} Video`,
        details: {
          description: "",
        },
      })

      return self.draft
    },
    saveCopy: flow(function* () {
      if (!self.draft?.copiedFromId) {
        throw new Error("Draft does not specify a pitch to copy from")
      }
      const pitchApi = new PitchApi(self.environment.api)
      yield* toGenerator(pitchApi.copyAsDraft(self.draft.copiedFromId, self.draft.id))
      if (self.draft) {
        self.draft.copiedFromId = undefined
      }
    }),
    savePitchTimeline: flow(function* (timeline: Timeline, pitchIntentProps: PitchIntentProps) {
      if (!self.draft) {
        throw new Error("Draft is not set")
      }
      // short circuit if the timeline has already been saved
      if (timeline.sync.status === SyncStatus.Synced) {
        return undefined
      }

      if (timeline.sync.syncing) {
        throw new Error("Timeline is already being saved")
      }

      const draftPitchId = self.draft.id

      const api = new PitchApi(self.environment.api)
      if (self.draft.details?.name) {
        self.setName(self.draft.details?.name.trim())
      }
      yield* toGenerator(
        timeline.sync.run(() => {
          if (!self.draft || !self.draft.details || !self.draft.defaultName) {
            throw new Error("Draft or details is not set when savePitchTimeline is called")
          }
          return api.savePitchTimeline(
            draftPitchId,
            self.draft.details.name || self.draft.defaultName,
            timeline,
          )
        }),
      )

      // now that the draft has been saved, we can remove the reference to its source
      if (self.draft) {
        self.draft.copiedFromId = undefined
      }

      // put the studio pitch info into a waiting state until it is updated by the server
      const studioPitchInfo = self.pitchStore.studioPitchInfos.get(draftPitchId)

      if (studioPitchInfo) {
        studioPitchInfo?.setTimeline(timeline)
        studioPitchInfo?.setStreamableStatus(StreamableSummaryStatus.Waiting)
        const previousTimeline = studioPitchInfo?.timeline

        // once the timeline is saved, remove the original
        if (
          previousTimeline &&
          previousTimeline?.id !== timeline?.id &&
          // make sure the timeline isn't being used by another pitch
          Array.from(self.pitchStore.studioPitchInfos.entries()).every(
            ([pitchId, spi]) =>
              pitchId === draftPitchId || spi.timeline?.id !== previousTimeline.id,
          )
        ) {
          yield self.timelineStore.removeLocalTimeline(previousTimeline.id)
        }
      } else {
        self.pitchStore.createStudioPitchInfo(draftPitchId, {
          timeline: timeline,
          streamableStatus: StreamableSummaryStatus.Waiting,
          actionEntityId: pitchIntentProps.actionEntityId,
          actionType: pitchIntentProps.actionType,
          actionTargetId: pitchIntentProps.actionTargetId,
          assignmentUserId: pitchIntentProps.assignmentUserId,
        } as StudioPitchInfo)
      }

      return undefined
    }),
    savePitchAttachments: flow(function* () {
      if (!self.draft || !self.draft.details || !self.draft.defaultName) {
        throw new Error("Draft or details not set when savePitchAttachments is called")
      }
      if (self.draft.details?.name) {
        self.setName(self.draft.details?.name.trim())
      }
      const api = new PitchApi(self.environment.api)
      yield api.savePitchAttachments(
        self.draft.id,
        self.draft.details.name || self.draft.defaultName,
        self.draft.details.description ?? "",
        // only save attachments that have data (to excludes ones that only have labels)
        self.attachments.filter((a) => a.data),
      )
    }),
  }))
  .views((self) => ({
    get draftPayload() {
      if (!self.draft) {
        return {}
      }
      return {
        description: self.draft.details?.description,
        name: self.draft.details?.name || self.draft.defaultName,
        attachmentIds: self.attachments.map((a) => a.id),
      }
    },
    get originalPitchPayload() {
      if (!self.draft) {
        return {}
      }
      const pitch = self.pitchStore.pitches.get(self.draft?.id)
      return {
        name: pitch?.name || self.draft.defaultName,
        description: pitch?.description ?? "",
        attachmentIds: self.originalAttachments.map((attachment) => attachment.id),
      }
    },
  }))
  .views((self) => ({
    get isDirty() {
      return (
        self.hasUnsyncedAttachments ||
        !isEqual(self.draftPayload, self.originalPitchPayload) ||
        !isEqual(
          Array.from(self.originalAttachments.values()),
          Array.from(self.attachments.values()),
        )
      )
    },
    get attachmentCount() {
      // It's possible that an attachment is created when a label is changed even though no data is set
      if (self.draft?.details?.description) {
        return self.attachments.filter((a) => a.data).length + 1
      } else {
        return self.attachments.filter((a) => a.data).length
      }
    },
    get videoAttachmentTypes() {
      const attachmentTypes = self.attachments.map((a) => a.type)
      if (self.draft?.details?.description) {
        return attachmentTypes.concat(AttachmentType.Description)
      }
      return attachmentTypes
    },
  }))

export type PitchEditorStore = Instance<typeof PitchEditorStoreModel>
