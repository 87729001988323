import { Instance, types } from "mobx-state-tree"
import { DateTime } from "../utils/datetime-type"
import { StringEnum } from "../utils/string-enum-type"
import { ContentVisibility } from "./content-visibility"
import { EntityModel } from "./entity"

export enum MessageType {
  Text = "Text",
  Image = "Image",
  File = "File",
  Pitch = "Pitch",
  Playlist = "Playlist",
  Profile = "Profile",
  Invitation = "Invitation",
  GroupProfile = "GroupProfile",
}

export const ProfileMessageDataModel = types.model("ProfileMessageData").props({
  userId: types.string,
})
export type ProfileMessageData = Instance<typeof ProfileMessageDataModel>

export const PlaylistMessageDataModel = types.model("PlaylistMessageData").props({
  playlistId: types.string,
})

export type PlaylistMessageData = Instance<typeof PlaylistMessageDataModel>

export const PitchMessageDataModel = types.model("PitchMessageData").props({
  pitchId: types.string,
  entityId: types.maybe(types.string),
  visibility: types.maybe(StringEnum(ContentVisibility)),
  isFromPitchViewer: types.maybe(types.boolean),
})

export type PitchMessageData = Instance<typeof PitchMessageDataModel>

export const ImageMessageDataModel = types.model("ImageMessageData").props({
  assetId: types.string,
})

export type ImageMessageData = Instance<typeof ImageMessageDataModel>

export const FileMessageDataModel = types.model("FileMessageData").props({
  assetId: types.string,
})

export type FileMessageData = Instance<typeof FileMessageDataModel>

export const GroupProfileMessageDataModel = types.model("ProfileMessageData").props({
  groupId: types.string,
})
export type GroupProfileMessageData = Instance<typeof GroupProfileMessageDataModel>

export const MessageDataModel = types.union(
  types.string,
  PitchMessageDataModel,
  ImageMessageDataModel,
  FileMessageDataModel,
  PlaylistMessageDataModel,
  ProfileMessageDataModel,
  GroupProfileMessageDataModel,
)
export type MessageData = Instance<typeof MessageDataModel>

export const MessageModel = types
  .model("Message")
  .props({
    id: types.identifier,
    userId: types.string,
    conversationId: types.string,

    type: types.maybe(StringEnum(MessageType)),

    /** Every message type can have optional text too. Text messages only use this */
    textContent: types.maybe(types.string),

    /** If sending a pitch or an image, the metadata is stored here */
    data: types.maybe(MessageDataModel),
    createdUtc: types.maybe(DateTime),
    updatedUtc: types.maybe(DateTime),
  })
  .actions((self) => ({
    setConversationId(conversationId: string) {
      self.conversationId = conversationId
    },
  }))

export type Message = Instance<typeof MessageModel>

export enum ConversationUserStatus {
  Pending = "Pending",
  Accepted = "Accepted",
  Rejected = "Rejected",
}
export const ConversationModel = types.model("Conversation").props({
  id: types.identifier,
  users: types.array(EntityModel),
  lastMessage: types.maybe(types.safeReference(MessageModel)),
  createdUtc: types.maybe(DateTime),
  hasUnreadMessages: types.maybe(types.boolean),
  userStatus: types.optional(StringEnum(ConversationUserStatus), ConversationUserStatus.Rejected),

  /** True if this conversation only exists on the client */
  draft: types.optional(types.boolean, false),
})

export type Conversation = Instance<typeof ConversationModel>
