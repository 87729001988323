import React, { CSSProperties, ReactNode } from "react"
import { CSSObject } from "@emotion/react"
import { Dialog, Fade } from "@mui/material"
import Button from "../button/button"
import { color } from "../../theme/color"
import { Icons } from "../icon/icon"
import { TransitionProps } from "@mui/material/transitions"

interface ModalProps {
  open: boolean
  title: string
  children: ReactNode
  style?: CSSObject
  backdropStyle?: CSSObject
  onClose: (boolean) => void
}

const CLOSE_BUTTON: CSSObject = {
  color: color.dimText,
  position: "absolute",
  top: "10px",
  right: "10px",
  cursor: "pointer",
  zIndex: 10,
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Fade ref={ref} {...props} />
})

export function Modal(props: ModalProps) {
  const { children, open, onClose, title, style, backdropStyle } = props
  return (
    <Dialog
      open={open}
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
      aria-describedby={title}
      BackdropProps={{
        style: { backgroundColor: color.halfTransparentWhite, ...backdropStyle } as CSSProperties,
      }}
      PaperProps={{ sx: style }}
      TransitionComponent={Transition}
    >
      <Button css={CLOSE_BUTTON} preset="clear" onClick={() => onClose(!open)} icon={Icons.Close} />
      {children}
    </Dialog>
  )
}

export default Modal
